import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import custom from "../../Assets/Projects/custom.png";
import hunza from "../../Assets/Projects/hunza.png";
import project_c from "../../Assets/Projects/project-c.png";
import bookmi from "../../Assets/Projects/book.png";
import lms from "../../Assets/Projects/lms.png";
import kado from "../../Assets/Projects/kado.png";
import purify from "../../Assets/Projects/purify.png";
import Moltovino from "../../Assets/Projects/moltovino.png";
import Expo from "../../Assets/Projects/expo.png";


function Projects() {
    return (
        <Container fluid className="project-section">
            <Particle />
            <Container>
                <h1 className="project-heading">
                    My Recent <strong className="purple">Works </strong>
                </h1>
                <p style={{ color: "white" }}>
                    Here are a few projects I've worked on recently.
                </p>
                <Row
                    style={{ justifyContent: "center", paddingBottom: "10px" }}
                >
                      <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={Expo}
                            isBlog={false}
                            title="Digital Signage System"
                            technologies={[
                                "Nodejs",
                                "Express",
                                "Firebase",
                                "MongoDB",
                                "Angular JS",
                                "JS",
                                "HTML5",
                                "CSS3",
                            ]}
                            description="
                            This sophisticated digital signage system, developed with Angular and TypeScript, and utilizing Firebase for real-time content management and notifications, is designed to offer a dynamic and interactive display solution. It enables businesses and organizations to create, manage, and deploy content across multiple digital signage devices seamlessly. Core features include an intuitive content management interface, real-time content updates, customizable templates, and comprehensive scheduling capabilities. With Firebase integration, the system ensures that all displays are instantly updated with the latest content, whether it's promotional material, informational signage, or interactive media. Ideal for retail environments, corporate settings, educational institutions, and public spaces looking to engage their audience with timely and relevant digital content, this system provides a powerful tool for enhancing communication and marketing efforts through visually appealing and up-to-date digital displays."
                        />
                    </Col>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={Moltovino}
                            isBlog={false}
                            title="Digital Pos"
                            technologies={[
                                "nodejs",
                                "express",
                                "docker",
                                "mongoDB",
                                "JS",
                                "HTML5",
                                "CSS3",
                            ]}
                            description="MoltoVinoPOS is a bespoke digital Point of Sale (POS) solution meticulously crafted using the MERN stack (MongoDB, Express.js, React, and Node.js), with Firebase for real-time notifications, tailored for the MoltoVino restaurant chain. This specialized system is designed to streamline the operational workflow of MoltoVino's multiple locations, facilitating efficient order processing, inventory management, and customer service. Key features include multi-location support, real-time inventory updates, order management, and seamless user authentication, all integrated within a user-friendly interface. MoltoVinoPOS enhances the dining experience by ensuring prompt order handling and providing staff with instant alerts on stock levels and customer feedback through Firebase notifications, making it an essential tool for elevating service standards across the MoltoVino chain."
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={bookmi}
                            isBlog={false}
                            title="BookMi"
                            technologies={[
                                "php",
                                "larvael",
                                "docker",
                                "JS",
                                "HTML5",
                                "CSS3",
                            ]}
                            description="BookMi is a web-based application developed using the Laravel framework, designed to simplify appointment booking with OTP (One-Time Password) authentication for enhanced security. The project's core features include user registration and authentication, a directory of service providers, an intuitive appointment booking system, and real-time notifications for both users and service providers. With BookMi, users can seamlessly schedule appointments with their preferred service providers, ensuring a convenient and secure experience for all."
                            demoLink="https://bookmee.co.uk/"
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={project_c}
                            isBlog={false}
                            title="Project Central"
                            technologies={[
                                "php",
                                "larvael",
                                "docker",
                                "JS",
                                "HTML5",
                                "CSS3",
                            ]}
                            description="Project Central is a high-performance stock management application built on the Laravel framework. Tailored for enterprise use, it excels in overseeing inventory across diverse warehouses. Key features include multi-warehouse support, real-time inventory tracking, comprehensive order management, robust reporting and analytics, role-based access control, and seamless integration capabilities. Designed for scalability, Project Central empowers organizations to enhance productivity, reduce wastage, and make data-driven decisions, ultimately optimizing their supply chain operations."
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={hunza}
                            isBlog={false}
                            title="Hunza Trek & Tours"
                            technologies={[
                                "php",
                                "Wordpress",
                                "CMS",
                                "JS",
                                "HTML5",
                                "CSS3",
                            ]}
                            description="Hunza Trek and Tours is a dynamic website designed and developed using PHP and WordPress, tailored for a premier travel and tours company. The website serves as a gateway for travelers seeking to explore the enchanting region of Hunza and beyond. With its user-friendly interface and captivating design, it offers a seamless experience for travelers looking to discover the beauty and culture of this unique destination."
                            demoLink="https://hunzatrekandtours.com/"
                        />
                    </Col>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={purify}
                            isBlog={false}
                            technologies={[
                                "php",
                                "Wordpress",
                                "WP plugin Development",
                                "JS",
                                "HTML5",
                                "CSS3",
                            ]}
                            title="Purify 4 life"
                            description="For Purify 4 Life, I designed and developed a custom WordPress plugin aimed at connecting users with nearby physical trainers. Leveraging the power of WordPress, the plugin seamlessly integrates into the website, providing an intuitive interface for users to locate fitness professionals in their vicinity. The plugin's functionality enhances the user experience by offering a convenient and efficient way to find qualified trainers. This project showcases my ability to leverage WordPress as a versatile platform for creating tailored solutions that address specific business needs, in this case, facilitating the connection between fitness enthusiasts and local trainers through a seamlessly integrated plugin."
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={custom}
                            isBlog={false}
                            technologies={[
                                "php",
                                "Wordpress",
                                "CMS",
                                "JS",
                                "HTML5",
                                "CSS3",
                            ]}
                            title="iwantcustomgift"
                            description="iWantCustomGift is a feature-rich e-commerce website crafted using PHP and WordPress, with a focus on offering a wide range of personalized and custom-made gifts. This online platform provides a convenient and engaging shopping experience for customers looking to find that perfect, unique gift for their loved ones or special occasions."
                            demoLink="https://iwantcustomgift.com/"
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={kado}
                            isBlog={false}
                            technologies={[
                                "php",
                                "Wordpress",
                                "CMS",
                                "JS",
                                "HTML5",
                                "CSS3",
                            ]}
                            title="KADO"
                            description="I collaborated with Kado to develop their website using WordPress, focusing on a modern and user-friendly design. Customizing the site to align with Kado's brand identity, I ensured a professional appearance and seamless navigation. Leveraging WordPress's features, the platform is responsive and easy to maintain, allowing Kado to showcase their details effectively. This project highlights my commitment to delivering tailored solutions, utilizing WordPress for efficient and user-friendly web experiences."
                            demoLink="https://kado.org.pk/"
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={lms}
                            isBlog={false}
                            title="Techscape Lms"
                            technologies={[
                                "php",
                                "Moodle",
                                "CMS",
                                "JS",
                                "HTML5",
                                "CSS3",
                            ]}
                            description="The Moodle-based Learning Management System (LMS) is a robust and versatile online education platform designed to facilitate seamless learning and course management. Developed using PHP and built on the Moodle framework, this LMS offers a comprehensive solution for educational institutions, corporations, and organizations seeking an efficient way to deliver and manage e-learning content."
                            demoLink="http://lms.techscape.pk/"
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Projects;
