import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";

function ProjectCards(props) {
  const [showProjectModal, setShowProjectModal] = useState(false);
  const handleCloseProjectModal = () => setShowProjectModal(false);
  const handleShow = () => setShowProjectModal(true);

    return (
    <>
      <Card className="project-card-view">
        <Card.Img variant="top" src={props.imgPath} alt="card-img" />
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <div>
            <ul className="technologies">
              {props.technologies &&
                props.technologies.map((element, index) => {
                  return <li className="technology">{element}</li>;
                })}
            </ul>
          </div>
          <Button variant="primary" onClick={handleShow}>
            Read More
          </Button>
          {props.ghLink && (
            <Button variant="primary" href={props.ghLink} target="_blank">
              <BsGithub /> &nbsp;
              {props.isBlog ? "Blog" : "GitHub"}
            </Button>
          )}
          {"\n"}
          {"\n"}

          {!props.isBlog && props.demoLink && (
            <Button variant="primary" href={props.demoLink} target="_blank" style={{ marginLeft: "10px" }}>
              <CgWebsite /> &nbsp;
              {"Demo"}
            </Button>
          )}
        </Card.Body>
      </Card>

      <Modal show={showProjectModal} onHide={handleCloseProjectModal}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header> 
        <Modal.Body>
          <h5>Technologies</h5>
          <div>
            <ul className="technologies details-modal">
              {props.technologies &&
                props.technologies.map((element, index) => {
                  return <li className="technology">{element}</li>;
                })}
            </ul>
          </div>
          <h5>Description</h5>
          <p>{props.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseProjectModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ProjectCards;
